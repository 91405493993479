@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.neo.regex.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val insert_regex_hint: StringResource by 
      lazy { init_insert_regex_hint() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("app_name", CommonMainString0.app_name)
  map.put("insert_regex_hint", CommonMainString0.insert_regex_hint)
}

public val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neo.regex.resources/values/strings.commonMain.cvr", 10, 28),
    )
)

public val Res.string.insert_regex_hint: StringResource
  get() = CommonMainString0.insert_regex_hint

private fun init_insert_regex_hint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:insert_regex_hint", "insert_regex_hint",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neo.regex.resources/values/strings.commonMain.cvr", 39, 57),
    )
)
