@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.neo.regex.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val flavicon: DrawableResource by 
      lazy { init_flavicon() }

  public val ic_redo_24: DrawableResource by 
      lazy { init_ic_redo_24() }

  public val ic_undo_24: DrawableResource by 
      lazy { init_ic_undo_24() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("flavicon", CommonMainDrawable0.flavicon)
  map.put("ic_redo_24", CommonMainDrawable0.ic_redo_24)
  map.put("ic_undo_24", CommonMainDrawable0.ic_undo_24)
}

public val Res.drawable.flavicon: DrawableResource
  get() = CommonMainDrawable0.flavicon

private fun init_flavicon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:flavicon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neo.regex.resources/drawable/flavicon.png", -1, -1),
    )
)

public val Res.drawable.ic_redo_24: DrawableResource
  get() = CommonMainDrawable0.ic_redo_24

private fun init_ic_redo_24(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_redo_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neo.regex.resources/drawable/ic_redo_24.xml", -1, -1),
    )
)

public val Res.drawable.ic_undo_24: DrawableResource
  get() = CommonMainDrawable0.ic_undo_24

private fun init_ic_undo_24(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_undo_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.neo.regex.resources/drawable/ic_undo_24.xml", -1, -1),
    )
)
